:root {
	--black: black;
	--black-3: #333;
	--white: white;
	--gainsboro: #e7e7e7;
	--dark-slate-grey: #404040;
	--powder-blue: #b6b6b6;
	--dodger-blue: #d4d4d4;
	--black-2: #231f20;
	--blue-hover: rgba(255, 255, 255, 0.06);
	--steel-blue: #9fa0a0;
	--sea-green-2: black;
	--medium-sea-green: black;
	--gainsboro-2: #e2e3e4;
	--section-hover: rgba(255, 255, 255, 0.47);
	--indigo: black;
	--sea-green: black;
	--steel-blue-2: rgba(47, 108, 160, 0.2);
	--dark-slate-grey-2: black;
	--alice-blue: #e8f0fe;
	--gold: black;
}

.slider {
	margin-top: 0;
	position: relative;
}

.navigation {
	z-index: 2000;
	margin-bottom: 0;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.link-block {
	color: #383838;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;
	height: 200px;
	font-family: Open Sans, sans-serif;
	font-weight: 300;
	text-decoration: none;
	transition: all 0.45s;
	display: flex;
}

.link-block:hover {
	background-color: #292929;
	border-radius: 10px;
	transform: scale(1.1);
}

.link-block.out {
	display: none;
}

.icon-row {
	border-top: 0px solid var(--black);
	background-color: #000;
	border-bottom: 0 solid #0a0a0a;
	justify-content: space-around;
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
}

.service-icon {
	width: 60%;
	max-width: 80px;
	margin-top: 0;
	margin-bottom: 0;
}

.service-title {
	color: var(--black-3);
	text-align: center;
	text-transform: none;
	margin-top: 10px;
	font-family: Inter, sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
}

.service-title.mortgage {
	color: var(--white);
	text-align: center;
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 600;
}

.service-title.atm {
	color: var(--white);
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 600;
}

.service-title.mobile-banking,
.service-title.acount {
	color: var(--white);
	font-family: Inter, sans-serif;
	font-size: 16px;
}

.top-nav {
	justify-content: space-between;
	align-items: center;
	height: auto;
	padding: 20px 100px;
	display: flex;
}

.navbar {
	background-color: var(--black);
	flex-direction: column;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	position: static;
}

.brand {
	width: 100%;
	max-width: 300px;
}

.brand.w--current {
	width: 100%;
}

.nav-link {
	color: var(--white);
	text-transform: none;
	margin-left: 0;
	margin-right: 0;
	padding-left: 60px;
	padding-right: 60px;
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 0;
	transition: all 0.45s;
}

.nav-link:hover {
	color: var(--white);
	text-decoration: underline;
}

.nav-link:active {
	flex: 0 auto;
}

.nav-link.out {
	display: none;
}

.nav-link-row {
	justify-content: space-around;
	align-items: center;
	display: block;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.nav-menu {
	z-index: 200;
	background-color: #e7e7e7;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	margin-top: 98px;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.button {
	background-color: var(--powder-blue);
	color: #333;
	background-image: url('/20181016082423/assets/images/lock_black.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 100px;
	align-items: center;
	height: auto;
	padding: 9px 25px 9px 40px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.button:hover {
	border-style: solid;
	border-color: var(--black);
	color: var(--black-3);
	background-color: rgba(0, 0, 0, 0);
}

.login-field {
	float: left;
	background-color: #efefef;
	border: 1px #000;
	border-radius: 3px;
	width: 150px;
	height: 30px;
	margin-bottom: 0;
	margin-right: 5px;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

.login-form-wrapper {
	opacity: 0;
	background-color: #fff;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-bottom: 0;
	margin-right: 0;
	padding: 20px 40px;
	transform: translate(0, -105px);
	display: inline-block;
}

.login-div {
	z-index: 400;
	color: #505050;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-top: 37.4px;
	margin-right: 20px;
	position: absolute;
	top: 0;
	right: 0;
	overflow: hidden;
}

.button-2 {
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	background-color: #000;
	padding: 4px 15px;
	font-family: Exo, sans-serif;
	text-decoration: none;
	display: inline-block;
}

.button-2.login-button {
	float: left;
	color: var(--black-3);
	background-color: rgba(0, 0, 0, 0.22);
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 3px;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	transition: all 0.3s;
	display: block;
}

.button-2.login-button:hover {
	border: 2px solid var(--powder-blue);
	background-color: var(--white);
}

.news-section {
	opacity: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 140px 40px;
	display: flex;
}

.div-block-2 {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: flex-start;
	width: 30%;
	height: auto;
	display: flex;
}

.div-block-3 {
	border-radius: 5px;
	height: auto;
	overflow: hidden;
}

.heading-2 {
	font-family: Inter, sans-serif;
	font-size: 26px;
	font-weight: 600;
	margin-top: 20px;
	line-height: 30px;
}

.image-3 {
	border-radius: 5px;
	width: 100%;
	max-width: none;
}

.div-block-4,
.div-block-5 {
	border-radius: 5px;
	height: auto;
	overflow: hidden;
}

.button-3 {
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Inter, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.div-block-6 .div-block-7 img,
.news-see-all .div-block-7 img,
.testimonials-see-all .div-block-7 img {
	width: 0px;
	transition: width 450ms ease 0s;
}

.div-block-6:hover .div-block-7,
.news-see-all:hover .div-block-7,
.testimonials-see-all:hover .div-block-7 {
	transition: width 450ms ease 0s;
}

.div-block-6:hover .div-block-7 img,
.news-see-all:hover .div-block-7 img,
.testimonials-see-all:hover .div-block-7 img {
	transition: width 450ms ease 0s;
	width: 35px;
}

.button-3:hover {
	padding-right: 0;
	text-decoration: underline;
}

.utility-page-wrap {
	justify-content: center;
	align-items: center;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	max-height: 100%;
	display: flex;
}

.utility-page-content {
	text-align: center;
	flex-direction: column;
	width: 260px;
	display: flex;
}

.utility-page-form {
	flex-direction: column;
	align-items: stretch;
	display: flex;
}

.search {
	opacity: 1;
	align-items: center;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.search-input {
	border-radius: 20px;
	height: 38px;
	margin-bottom: 0;
	margin-right: -38px;
}

.search-button {
	background-color: var(--powder-blue);
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 20px;
	width: 38px;
	height: 38px;
	margin-left: 0;
	margin-right: 0;
	transition: all 0.45s;
}

.search-button:hover {
	border: 2px solid var(--black);
	background-color: var(--white);
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-repeat: no-repeat;
	background-size: 15px;
}

.div-block-6 {
	align-items: center;
	width: auto;
	height: 45px;
	margin-bottom: 20px;
	display: flex;
	overflow: hidden;
}

.grey-arrow {
	width: 0;
	margin-left: 10px;
}

.div-block-7 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.div-block-8 {
	justify-content: flex-end;
	align-items: center;
	width: auto;
	display: flex;
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.grey-arrow-2,
.grey-arrow-3,
.grey-arrow-1,
.grey-arrow-4 {
	width: 0;
	margin-left: 10px;
}

.image-4,
.image-5 {
	width: 100%;
	max-width: none;
}

.callout-image {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 550px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	background-color: #333;
}

.heading-3 {
	color: var(--white);
	text-align: left;
	font-family: Inter, sans-serif;
	font-size: 55px;
	font-weight: 200;
	line-height: 55px;
}

.text-block {
	color: var(--white);
	text-align: left;
	font-family: Inter, sans-serif;
	font-size: 25px;
	font-weight: 300;
	line-height: 36px;
}

.blue-button {
	border: 2px solid var(--powder-blue);
	background-color: var(--white);
	color: #333;
	text-transform: uppercase;
	border-radius: 100px;
	align-items: center;
	height: auto;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 9px 25px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.blue-button:hover {
	border-style: solid;
	border-color: var(--white);
	background-color: var(--white);
	color: var(--black);
}

.blue-button.bottom:hover {
	border-color: var(--black);
}

.text-block-2,
.text-block-3,
.text-block-4 {
	font-family: Inter, sans-serif;
}

.heading-4 {
	color: var(--black-3);
	text-transform: none;
	text-shadow: none;
	margin-top: 0;
	font-family: Montserrat, sans-serif;
	font-size: 50px;
	font-weight: 800;
	line-height: 50px;
}

.heading-4.white {
	color: var(--white);
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
	font-family: Inter, sans-serif;
	font-size: 60px;
	font-weight: 600;
	line-height: 55px;
}

.testimonial {
	background-color: var(--white);
	background-position: 0 0, 50% 54%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	height: 600px;
	margin-left: 0;
	padding-top: 40px;
	padding-bottom: 100px;
	padding-left: 140px;
	display: flex;
	background-color: #333;
}

.footer-section {
	background-color: rgba(0, 0, 0, 0.05);
	justify-content: center;
	align-items: center;
	height: auto;
	padding-left: 140px;
	padding-right: 140px;
	display: flex;
}

.paragraph-2 {
	color: var(--white);
	max-width: 600px;
	margin-bottom: 0;
	padding-left: 0;
	font-family: Inter, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
}

.heading-6 {
	color: var(--white);
	background-image: url('/20181016082423/assets/images/Testimonial-icon.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 45px;
	padding-left: 60px;
	font-family: Montserrat, sans-serif;
	font-size: 45px;
	font-weight: 700;
}

.div-block-12 {
	justify-content: space-between;
	width: 100%;
	display: flex;
}

.heading-7 {
	background-image: url('/20181016082423/assets/images/News-icon.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 45px;
	margin-bottom: 20px;
	margin-left: 0;
	padding-left: 60px;
	font-family: Inter, sans-serif;
	font-size: 45px;
	font-weight: 700;
	line-height: 50px;
}

.div-block-14 {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 60%;
	display: flex;
}

.heading-8 {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 40px;
	font-family: Inter, sans-serif;
}

.slider-4 {
	background-color: rgba(0, 0, 0, 0);
	width: 663px;
	height: auto;
}

.right-arrow-2 {
	width: 20px;
	height: auto;
	margin-bottom: 0;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.left-arrow-2 {
	width: 20px;
	height: auto;
	margin-bottom: 0;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: auto;
}

.icon-5,
.icon-6 {
	font-size: 20px;
}

.slide-nav-3 {
	height: 20px;
	padding-top: 10px;
	font-size: 10px;
	display: none;
}

.slide-3 {
	height: auto;
	min-height: auto;
	padding-left: 40px;
}

.slide-4 {
	margin-bottom: 0;
	padding-left: 40px;
}

.mask-2 {
	height: auto;
}

.contact-us {
	background-color: #1a1a1a;
	background-image: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;
	padding-left: 140px;
	padding-right: 140px;
	display: flex;
}

.text-block-7 {
	color: var(--white);
	text-align: left;
	max-width: 1300px;
	margin-bottom: 20px;
	padding-top: 40px;
	padding-bottom: 0;
	font-family: Inter, sans-serif;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	margin-bottom: 0px;
}

.div-block-15 {
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	display: flex;
}

.div-block-16 {
	justify-content: space-between;
	padding-top: 20px;
	display: flex;
}

.link-block-2 {
	width: 30%;
	max-width: 30%;
	height: auto;
	margin-right: 20px;
}

.div-block-17 {
	justify-content: flex-start;
	align-items: center;
	width: 80%;
	display: flex;
}

.div-block-18 {
	justify-content: flex-end;
	align-items: center;
	width: 20%;
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
}

.image-8 {
	width: 160px;
}

.text-block-8 {
	width: auto;
	margin-left: 0;
	font-family: Inter, sans-serif;
	font-size: 15px;
	font-weight: 400;
}

.footer-grip-link {
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	width: 133px;
	min-width: 0;
	height: 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link.gray {
	background-image: url('/20181016082423/assets/images/globalreach_grey.svg');
	background-size: 130px 29px;
}

.grip-logo-text-box {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box.webdevby-gray {
	color: #797676;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-signoff-link {
	color: var(--black);
	letter-spacing: 1px;
	font-family: Inter, sans-serif;
	font-size: 0.8rem;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	color: #000;
}

.footer-copyright {
	color: var(--black);
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-family: Inter, sans-serif;
	font-size: 0.9rem;
	display: inline-block;
}

.footer-copyright h1 {
	display: inline;
	padding: 0px;
	margin: 0px;
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
}

.column-3 {
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
	font-family: Montserrat, sans-serif;
	display: flex;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 0;
	display: flex;
}

.footer-signoff-links {
	border-bottom: 2px solid rgba(78, 77, 93, 0.5);
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	border-bottom-color: rgba(0, 0, 0, 0);
}

.columns {
	border-top: 1px solid #a0a0a8;
	justify-content: space-between;
	align-items: center;
	height: auto;
	margin-top: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
}

.slide-link {
	color: var(--white);
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.slide-link:hover {
	padding-right: 0;
}

.slide-link.enroll {
	border-bottom: 2px solid var(--white);
	margin-left: 0;
	margin-right: 0;
	font-family: Inter, sans-serif;
	font-weight: 300;
}

.slide-link.enroll:hover {
	opacity: 0.65;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.div-block-19 {
	align-items: flex-end;
	width: 100%;
	display: flex;
}

.news-see-all {
	align-items: center;
	width: auto;
	height: 45px;
	margin-bottom: 10px;
	margin-left: 20px;
	display: flex;
	overflow: hidden;
}

.news-button {
	color: #333;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Inter, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.news-button:hover {
	padding-right: 0;
}

.news-arrow {
	width: 0;
	margin-left: 10px;
}

.nav-dropdown-row {
	border-top: 3px solid var(--dark-slate-grey);
	min-width: 500px;
}

.nav-drop-list {
	background-color: #ede8e2;
	background-image: url('/20181016082423/assets/images/exclusive-paper.png');
	border-bottom: 3px solid #ad3b48;
	box-shadow: 0 3px 8px -3px rgba(95, 87, 76, 0.3);
}

.nav-drop-list.w--open {
	border-bottom-color: var(--white);
	background-color: #fff;
	background-image: none;
}

.nav-drop-list-link {
	color: var(--dark-slate-grey);
	background-color: rgba(255, 255, 255, 0.12);
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	padding: 0.625rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.125rem;
	text-decoration: none;
}

.nav-drop-list-link:hover {
	background-color: rgba(184, 184, 184, 0.12);
}

.nav-drop-list-link.w--current {
	color: var(--dodger-blue);
}

.nav-mega-menu-column-title {
	color: var(--dark-slate-grey);
	border: 1px #000;
	margin-bottom: 1rem;
	padding-top: 0.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5rem;
}

.nav-dropdown-column-content-wrap {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.nav-drop.hidden-mobile {
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
}

.dropdown-toggle-2 {
	padding: 0;
}

.div-block-20 {
	align-items: flex-end;
	display: flex;
}

.testimonials-see-all {
	color: var(--white);
	align-items: center;
	width: auto;
	height: 45px;
	margin-bottom: 0;
	margin-left: 20px;
	display: flex;
	overflow: hidden;
}

.testimonial-arrow {
	width: 0;
	margin-left: 10px;
}

.testimonial-button {
	color: var(--white);
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Inter, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.testimonial-button:hover {
	padding-right: 0;
}

.footer-nav {
	object-fit: contain;
	border-radius: 3px;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: auto;
	max-width: 1500px;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
	display: flex;
}

.footer-nav-li {
	text-align: center;
	justify-content: space-around;
	align-self: center;
	width: 920px;
	display: flex;
}

.footer-nav-a {
	color: #fff;
	background-color: #155d40;
	border-radius: 3px;
	padding: 14px 10px;
	font-size: 15px;
	font-weight: 700;
	line-height: 22px;
	text-decoration: none;
	display: block;
}

.footer-nav-a:hover {
	background-color: #1a8a4f;
}

.footer-nav-a.blue {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 50px;
	width: auto;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: Inter, sans-serif;
	transition: all 0.2s;
}

.footer-nav-a.blue:hover {
	background-color: var(--dark-slate-grey);
}

.footer-nav-a.orange {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 50px;
	width: auto;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: Inter, sans-serif;
	transition: all 0.2s;
}

.footer-nav-a.orange:hover {
	background-color: var(--blue-hover);
}

.footer-nav-a.purple {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 50px;
	width: auto;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: Inter, sans-serif;
	font-size: 15px;
	transition: all 0.2s;
}

.footer-nav-a.purple:hover {
	background-color: var(--blue-hover);
}

.social-icon {
	margin-top: -2px;
	margin-bottom: 0;
	margin-right: 10px;
}

.facebook-label {
	margin-bottom: 0;
	font-family: Inter, sans-serif;
	font-size: 14px;
	display: inline-block;
}

.section-footer {
	background-color: var(--black);
	flex-direction: row;
	justify-content: center;
	align-items: center;
	max-width: none;
	padding: 20px 100px;
	display: flex;
}

.nav-menu-2 {
	margin-top: 98px;
	position: fixed;
}

.header-strip {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: var(--black);
	padding: 5px 10px;
	padding-left: 100px;
}

.header-strip.sticky {
	position: fixed;
	z-index: 1000;
}

.header-strip .fdic {
	grid-column-gap: 7px;
	grid-row-gap: 7px;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;
	display: flex;
}

.header-strip .fdic .fdic-logo {
	background-image: url('/local/public/shared/assets/images/fdic-logo-white.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: contain;
	min-width: 39.76px;
	height: 16px;
}

.header-strip .fdic .fdic-text {
	color: #fff;
	font-family: "Source Sans 3", system-ui;
	font-size: 12.8px;
	font-style: italic;
	line-height: 13px;
	text-align: left;
}

.div-block-21 {
	height: auto;
	overflow: hidden;
}

.nav-menu-3 {
	background-color: var(--black);
	justify-content: center;
	margin-top: 0;
	position: static;
}

.account-button-mobile {
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	height: auto;
	padding: 9px 25px 9px 40px;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.account-button-mobile:hover {
	background-color: var(--steel-blue);
}

.div-block-22 {
	display: none;
}

.mobile-search {
	opacity: 1;
	object-fit: fill;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	display: flex;
	overflow: visible;
	margin-left: 12px;
	transition: width 450ms ease 0s, opacity 200ms ease 0s;
}

.mobile-search.active {
	transition: width 450ms ease 0s, opacity 200ms ease 0s;
	opacity: 1;
	width: 200px;
}

.mobile-search.onlymob {
	display: none;
}

.button-copy {
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	height: auto;
	padding: 9px 25px 9px 40px;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.button-copy:hover {
	background-color: var(--steel-blue);
}

.left-nav-section-title {
	border-bottom: 1px rgba(0, 0, 0, 0.15);
	margin-top: 0;
}

.ordered-list {
	padding-left: 20px;
	line-height: 22px;
}

.heading-2-link {
	color: var(--sea-green-2);
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 32px;
	line-height: 25px;
	text-decoration: underline;
	transition: all 0.3s;
}

.heading-2-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: none;
	border-bottom-color: rgba(0, 0, 0, 0);
	line-height: 6px;
}

.left-nav-ul {
	background-color: rgba(0, 0, 0, 0);
	margin-bottom: 0.5rem;
	display: block;
}

.button-5 {
	border: 2px solid var(--powder-blue);
	background-color: var(--powder-blue);
	color: var(--black-3);
	text-align: center;
	text-transform: none;
	cursor: pointer;
	border-radius: 50px;
	justify-content: center;
	align-self: center;
	align-items: center;
	min-width: 100px;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-right: 16px;
	padding: 0.8rem 1rem 0.7rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.button-5:hover {
	border: 2px solid var(--powder-blue);
	background-color: var(--white);
	color: var(--powder-blue);
}

.button-5.secondary {
	border-color: var(--powder-blue);
	color: var(--black-3);
	text-align: center;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	transition: all 0.2s;
}

.button-5.secondary:hover {
	border-color: var(--powder-blue);
	background-color: var(--powder-blue);
	opacity: 1;
	color: var(--white);
	background-image: none;
}

.button-5.tertiary {
	color: #585b5d;
	background-color: rgba(0, 0, 0, 0);
	border: 2px solid #c4ccd1;
	min-width: 60px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-right: 0;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button-5.tertiary:hover {
	color: #000;
	background-color: #f1f1f1;
	transform: none;
}

.left-nav-wrapper {
	border-right: 1px rgba(228, 19, 86, 0.59);
	height: auto;
	margin-top: -3px;
	margin-left: 0;
	margin-right: 1rem;
	padding-left: 0.5rem;
	padding-right: 1rem;
}

.h6-link {
	color: var(--sea-green-2);
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 12px;
	line-height: 18px;
	text-decoration: underline;
	transition: all 0.45s;
}

.h6-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: none;
	border-bottom-color: rgba(0, 0, 0, 0);
	line-height: 6px;
}

.block-quote {
	border-left-width: 9px;
	border-left-color: var(--powder-blue);
	color: var(--dark-slate-grey);
	background-color: rgba(0, 0, 0, 0);
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	font-size: 1.35rem;
	line-height: 2rem;
}

.inside-content-sect {
	background-color: #fff;
	flex-direction: row;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
	padding-right: 0;
	display: block;
	position: relative;
}

.main-content-outer-wrap {
	align-items: flex-start;
	width: 100%;
	display: flex;
}

.h5-link {
	color: var(--sea-green-2);
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
	transition: all 0.45s;
}

.h5-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: none;
	border-bottom-color: rgba(0, 0, 0, 0);
	line-height: 6px;
}

.breadcrumbs-list-item {
	float: left;
	display: inline-block;
}

.left-nav-col {
	float: left;
	background-color: var(--gainsboro);
	border-right: 3px #e41356;
	flex: none;
	min-width: 25%;
	min-width: 200px;
	max-width: 250px;
	height: 100%;
	min-height: 500px;
	margin-top: 0;
	margin-right: 3vw;
	padding-top: 1rem;
	padding-bottom: 4rem;
	padding-right: 0.75px;
	font-family: Inter, sans-serif;
	width: 25%;
	min-width: 200px;
	max-width: 250px;
}

.breadcrumb-link {
	border-bottom: 1px solid var(--gainsboro-2) !important;
	color: var(--sea-green-2) !important;
	cursor: pointer;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	transition: all 0.45s;
	font-weight: 400 !important;
}

.breadcrumb-link:hover {
	color: var(--medium-sea-green);
	border-bottom-color: rgba(0, 0, 0, 0) !important;
}

.breadcrumb-link.current-bc-link {
	color: var(--black);
	border-bottom-style: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.main-content-inner-wrap {
	justify-content: center;
	padding-top: 2rem;
	font-family: Montserrat, sans-serif;
	display: flex;
	width: 100%;
	min-height: 500px;
}

.text-link {
	border-bottom: 1px solid var(--sea-green-2);
	color: #696b6d;
	cursor: pointer;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.45s;
}

.text-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.horiz-divider {
	background-color: rgba(0, 0, 0, 0.15);
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.h3-link {
	color: var(--sea-green-2);
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline;
	transition: all 0.45s;
}

.h3-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: none;
	border-bottom-color: rgba(0, 0, 0, 0);
	line-height: 6px;
	text-decoration: underline;
}

.h4-link {
	color: var(--sea-green-2);
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 18px;
	line-height: 24px;
	text-decoration: underline;
	transition: all 0.45s;
}

.h4-link:hover {
	color: var(--medium-sea-green);
	border-bottom-style: none;
	border-bottom-color: rgba(0, 0, 0, 0);
	line-height: 6px;
}

.left-nav-link-3 {
	color: #696b6d;
	text-transform: none;
	border-left: 2px rgba(0, 0, 0, 0);
	border-radius: 0;
	width: 218px;
	margin-bottom: 0.5rem;
	padding: 2px 0 2px 0.63rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.6rem;
	text-decoration: none;
	transition: all 0.3s;
	display: block;
}

.left-nav-link-3:hover {
	background-color: var(--section-hover);
	color: var(--sea-green-2);
	border-left-style: none;
	border-left-color: #e41356;
	width: 218px;
	padding-left: 0.63rem;
	padding-right: 0;
}

.left-nav-link-3.grandchild-link {
	text-transform: none;
	border-left-color: rgba(0, 0, 0, 0);
	border-radius: 0;
	width: 206px;
	padding-top: 1px;
	padding-bottom: 1px;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.5rem;
	transition: all 0.3s;
}

.left-nav-link-3.grandchild-link:hover {
	background-color: var(--section-hover);
	color: var(--sea-green-2);
	border-left-color: #e41356;
	width: 206px;
}

.left-nav-link-3.grandchild-link.w--current:hover {
	background-color: var(--section-hover);
}

.left-nav-link-3.greatgrandchild-link {
	text-transform: none;
	border-left-color: rgba(0, 0, 0, 0);
	width: 193px;
	padding-top: 1px;
	padding-bottom: 1px;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.3rem;
	transition: all 0.3s;
}

.left-nav-link-3.greatgrandchild-link:hover {
	box-shadow: none;
	color: var(--sea-green-2);
	border-left-color: #e41356;
	width: 192px;
}


.main-content {
	flex-direction: row;
	width: auto;
	padding-top: 0;
	font-family: Inter, sans-serif;
	display: block;
	flex-grow: 1;
}

.main-content.nosidebars {
	width: 65%;
	min-width: 800px;
}

.textlink-middleout {
	border-bottom: 1px solid var(--dodger-blue);
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.45s;
}

.textlink-middleout:hover {
	box-shadow: none;
	color: var(--dodger-blue);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.breadcrumb-divider {
	color: var(--sea-green-2);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	font-size: 1rem;
	display: inline-block;
}

.container-3 {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.container-3.inside-page-container {
	max-width: 1200px;
}

.breadcrumbs-list {
	color: #fff;
	margin-bottom: 1rem;
	padding-left: 0;
}

.left-nav-section-title-link {
	color: var(--sea-green-2);
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 5px;
	padding: 0.25rem 0 0.4rem 10px;
	font-size: 1.7rem;
	font-weight: 700;
	line-height: 23px;
	text-decoration: none !important;
	display: block;
}

.intro-paragraph {
	color: #424242;
	margin-bottom: 1.5rem;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.breadcrumbs-wrapper {
	color: #424242;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: block;
}

.content-outer-wrap {
	z-index: 2;
	border-top: 8px #629edc;
	height: auto;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 4rem;
	display: block;
	position: relative;
}

.paragraph-3,
.paragraph-4,
.paragraph-5,
.list,
.paragraph-6,
.paragraph-7,
.paragraph-8,
.paragraph-9,
.paragraph-10,
.paragraph-11 {
	line-height: 22px;
}

.paragraph-12 {
	color: #333;
	line-height: 22px;
}

.paragraph-13 {
	line-height: 22px;
}

.heading-9,
.heading-10 {
	color: #424242;
}

.section-3 {
	background-position: 50% 29%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 350px;
}

.page-title {
	color: #424242;
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0%;
	padding: 0 0 10px;
	font-size: 50px;
	font-weight: 700;
	line-height: 55px;
}

.footer-nav-social {
	text-align: center;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-self: center;
	width: 500px;
	display: flex;
}

.link {
	color: var(--dark-slate-grey);
	text-decoration: none;
}

.right-side-col {
	background-color: rgba(0, 0, 0, 0);
	flex: none;
	width: 20%;
	min-width: 160px;
	max-width: 250px;
	margin-top: 0;
	margin-left: 3vw;
	padding-top: 0;
	padding-left: 10px;
	font-family: Inter, sans-serif;
	position: relative;
}

.right-col-item-wrap {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 0.4rem;
}

.list-item-3 {
	margin-bottom: 10px;
}

.h2-right-column-title {
	color: var(--sea-green-2);
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
}

.h4-right-column-title {
	color: var(--sea-green-2);
	background-color: rgba(0, 0, 0, 0);
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
}

.h3-right-column-title {
	color: var(--sea-green-2);
	background-color: rgba(0, 0, 0, 0);
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
}

.right-column-link {
	border-bottom: 1px solid var(--sea-green-2);
	color: var(--dark-slate-grey);
	background-color: rgba(0, 0, 0, 0);
	margin-left: 10px;
	padding-left: 0;
	padding-right: 0;
	text-decoration: none;
	transition: all 0.3s;
}

.right-column-link:hover {
	color: var(--medium-sea-green);
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 1px rgba(0, 0, 0, 0);
}

.paragraph-14 {
	color: var(--dark-slate-grey);
}

.login-dropdown {
	float: left;
	background-color: #fff;
	border-style: none;
	width: 120px;
	height: 30px;
	margin-top: 4px;
	margin-bottom: 0;
	margin-right: 0;
	padding: 3px 10px;
}

.login-dropdown.login-field {
	background-color: var(--black);
	color: var(--white);
	margin-top: 0;
	margin-right: 5px;
}

.link-2 {
	float: left;
	color: #616265;
	text-align: right;
	width: auto;
	margin-top: 0;
	padding-top: 0;
	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	display: block;
}

.link-2:hover {
	color: #8b8b8b;
}

.text-block-9 {
	text-align: center;
	width: auto;
	margin-left: 0;
	margin-right: 0;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
	line-height: 22px;
	display: block;
}

.div-block-24 {
	margin-top: 5px;
	display: flex;
}

.form-block {
	opacity: 0;
	margin-top: 3px;
	transform: translate(0, -105px);
	transition: transform 450ms ease 0s, opacity 450ms ease 0s;
}

.form-block.active {
	transform-style: preserve-3d;
	transition: transform 450ms ease 0s, opacity 450ms ease 0s;
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
}

.paragraph {
	color: #333;
	text-align: left;
	max-width: 625px;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.paragraph.white {
	color: var(--white);
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
	font-family: Inter, sans-serif;
}

.icon-2 {
	font-size: 15px;
	display: flex;
}

.icon-2:hover {
	color: var(--white);
}

.slide-5 {
	background-position: 0 0, 34%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.account-login-cb {
	background-color: rgba(0, 0, 0, 0);
	background-image: linear-gradient(225deg, #000, rgba(255, 255, 255, 0.06)), url('/20181016082423/assets/images/Login_bg-min.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 70vh;
	margin-top: 0;
	padding: 0 100px;
	display: flex;
	position: relative;
	overflow: visible;
}

.slide-6 {
	background-color: rgba(0, 0, 0, 0);
	background-position: 0 0, 71%;
	background-size: auto, cover;
}

.account-login-wrapper {
	background-color: var(--sea-green-2);
	opacity: 1;
	background-image: linear-gradient(84deg, #000, #332d2d);
	border-radius: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 525px;
	height: 100%;
	max-height: 375px;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
}

.account-login-wrapper:hover {
	background-image: linear-gradient(84deg, #000, #332d2d), linear-gradient(158deg, #000, rgba(255, 255, 255, 0)), url('/20181016082423/assets/images/1000_F_530949542_ynBPIqHf7zinflgrpjVjKVYp09I5Mcrf.jpg');
	background-position: 0 0, 0 0, 50%;
	background-size: auto, auto, cover;
}

.slider-5 {
	background-color: rgba(0, 0, 0, 0);
	width: 50%;
	height: 70vh;
	margin-top: 0;
}

.right-slideshow-arrow {
	color: var(--white);
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 50px;
	margin-left: 0;
	margin-right: 0;
	font-weight: 700;
	position: relative !important;
	transition: opacity 0.2s;
	order: 3;
	float: left;
}

.right-slideshow-arrow:hover {
	color: var(--gainsboro);
}

.slide-nav-4 {
	color: var(--white);
	text-align: left;
	border: 1px #fff;
	position: relative;
	order: 2;
	float: left;
	font-size: 0.8rem;
	margin-top: 7px;
	height: 40px;
	margin-left: 4px;
	margin-right: 4px;
}

.left-slideshow-arrow {
	color: var(--white);
	justify-content: flex-start;
	align-items: center;
	width: 20px;
	height: 50px;
	position: relative !important;
	font-weight: 700;
	right: auto;
	float: left;
	order: 1;
}

.left-slideshow-arrow:hover {
	color: var(--white);
}

.slide-7 {
	background-position: 0 0, 50% 0;
	background-size: auto, auto;
}

.bg-color-wrapper {
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	display: flex;
}

.form {
	font-family: Montserrat, sans-serif;
}

.online-banking-form {
	width: 100%;
	margin-bottom: 0;
}

.login-button {
	border: 2px solid var(--powder-blue);
	background-color: var(--powder-blue);
	color: #333;
	border-radius: 100px;
	align-items: center;
	height: auto;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	padding: 9px 25px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.login-button:hover {
	border-style: solid;
	border-color: var(--white);
	background-color: var(--white);
	color: var(--black);
}

.login-button.top {
	margin-right: 1rem;
}

.online-banking-heading {
	color: var(--white);
	text-transform: none;
	text-shadow: none;
	margin-top: 0;
	font-family: Inter, sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;
}

.slideshow-wrapper {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	padding-top: 100px;
	padding-left: 100px;
	padding-right: 100px;
	display: flex;
}

.text-field {
	border: 1px #000;
	border-radius: 5px;
	font-family: Inter, sans-serif;
}

.logo-overlay {
	z-index: -1;
	opacity: 1;
	background-image: linear-gradient(#000, rgba(255, 255, 255, 0)), url('/20181016082423/assets/images/1000_F_530949542_ynBPIqHf7zinflgrpjVjKVYp09I5Mcrf.jpg'), url('/20181016082423/assets/images/vision-bank-symbol.svg');
	background-position: 0 0, 50%, 50%;
	background-repeat: repeat, no-repeat, no-repeat;
	background-size: auto, cover, cover;
	width: 50%;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.div-block-26 {
	align-items: center;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	display: flex;
}

.bold-text {
	font-family: Inter, sans-serif;
}

.div-block-27 {
	grid-column-gap: 5%;
	grid-row-gap: 5%;
	justify-content: space-around;
	width: 1300px;
	max-width: 1300px;
	display: flex;
}

.bold-text-2 {
	color: var(--white);
}

.desktop-search-2,
.mobile-search-copy {
	opacity: 1;
	align-items: center;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.mobile-search-copy,
.mobile-search-2 {
	opacity: 1;
	align-items: center;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	display: none;
	overflow: hidden;
}

.newsimgcon {
	border-radius: 5px;
	height: auto;
	margin-bottom: 10px;
	overflow: hidden;
}

.newscon {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: flex-start;
	width: 30%;
	height: auto;
	display: flex;
}

.newswrapper {
	justify-content: space-between;
	width: 100%;
	display: flex;
}

.newstitlewrapper {
	align-items: flex-end;
	width: 100%;
	display: flex;
}

.servicescon {
	grid-column-gap: 5%;
	grid-row-gap: 5%;
	justify-content: space-around;
	width: 1300px;
	max-width: 1300px;
	display: flex;
}

.callouttextwrapper {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 60%;
	display: flex;
}

.testimonialscon {
	align-items: flex-end;
	display: flex;
}

.button-sub-2 {
	color: #333;
	background-color: #b6b6b6;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 100px;
	align-items: center;
	height: auto;
	padding: 9px 20px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
	text-align: center;
}

.button-sub-2.desktop {
	margin-right: 20px;
}

.button-sub-2.desktop-copy {
	margin-right: 0;
}

.button-sub-2:hover {
	color: #333;
	background-color: rgba(0, 0, 0, 0);
	border-style: solid;
	border-color: #000;
}

.login-popup {
	z-index: 2222;
	border-radius: 5px;
	position: absolute;
	top: 135px;
	bottom: auto;
	left: auto;
	right: 30px;
	display: none;
	opacity: 0;
	transition: opacity 300ms ease;
	overflow: hidden;
}

.mm-wrapper_opened .login-popup {
	display: none !important;
}

.login-popup.active {
	display: block;
	opacity: 1;
}

.button-wrapper-copy {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 16px;
}

.button-wrapper-copy {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 16px;
	display: flex;
}

.account-login-btn {
	display: none !important;
}

@media screen and (min-width: 992px) {
	.login-popup {
		display: none !important;
	}
}


@media screen and (max-width: 991px) {
	.account-login-btn.mobile {
		display: none !important;
	}

	.account-login-btn {
		display: flex !important;
	}

	.button-wrapper-copy {
		justify-content: space-between;
		align-items: center;
		display: flex;
	}

	.login-popup {
		box-sizing: border-box;
		background-color: rgba(0, 0, 0, 0);
		justify-content: center;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
	}

	.slider {
		z-index: 410;
		margin-top: 98px;
	}

	.button-sub-2.desktop,
	.button-sub-2.desktop-copy {
		display: none;
	}

	.navigation {
		z-index: 999;
		margin-top: 101px;
		margin-bottom: -103px;
		position: fixed;
	}

	.link-block {
		width: 35%;
		height: 180px;
	}

	.icon-row {
		border-top-width: 0;
		border-bottom-width: 0;
		border-bottom-color: var(--black);
		flex-wrap: wrap;
	}

	.top-nav {
		padding-left: 40px;
		padding-right: 61px;
		position: relative;
	}

	.navbar {
		position: static;
	}

	.brand {
		max-width: 245px;
	}

	.nav-link {
		color: var(--black);
		width: auto;
		padding-top: 30px;
		padding-bottom: 30px;
		display: block;
	}

	.nav-link:hover {
		color: var(--dark-slate-grey);
		background-color: rgba(173, 175, 174, 0.15);
	}

	.nav-link-row {
		z-index: 900;
		height: 23px;
		margin-top: -26px;
		position: relative;
	}

	.nav-menu {
		background-color: var(--white);
	}

	.button {
		margin-right: 10px;
	}

	.login-field {
		width: 177px;
		height: 40px;
	}

	.login-div {
		border-radius: 5px;
		height: 107px;
		margin-top: 0;
		margin-left: 0;
		margin-right: -60px;
		right: 84px;
	}

	.button-2.login-button {
		height: 40px;
		padding-top: 10px;
	}

	.news-section {
		padding-left: 40px;
		padding-right: 40px;
	}

	.div-block-3,
	.div-block-4,
	.div-block-5 {
		height: auto;
	}

	.div-block-8 {
		z-index: 1000;
		width: auto;
		position: relative;
	}

	.callout-image {
		height: 400px;
	}

	.heading-3 {
		font-size: 45px;
		line-height: 50px;
	}

	.text-block {
		font-size: 20px;
		line-height: 25px;
	}

	.heading-4 {
		font-size: 40px;
		line-height: 40px;
	}

	.heading-4.white {
		font-size: 50px;
	}

	.testimonial {
		height: 500px;
		padding-bottom: 40px;
		padding-left: 10px;
	}

	.footer-section {
		padding-left: 40px;
		padding-right: 40px;
	}

	.paragraph-2 {
		max-width: 550px;
		font-size: 15px;
		line-height: 20px;
	}

	.heading-6 {
		font-size: 40px;
	}

	.heading-7 {
		background-size: 43px;
		font-size: 40px;
		line-height: 44px;
	}

	.div-block-14 {
		width: 80%;
	}

	.slider-4 {
		width: 612px;
	}

	.slide-3 {
		padding-left: 30px;
	}

	.contact-us {
		padding-left: 40px;
		padding-right: 40px;
	}

	.text-block-7 {
		text-align: center;
	}

	.link-block-2 {
		width: 40%;
	}

	.div-block-18 {
		width: 15%;
	}

	.image-8,
	.image-9 {
		width: 80%;
	}

	.text-block-8 {
		width: auto;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.footer-copyright {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 0;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.nav-dropdown-row {
		display: none;
	}

	.nav-drop {
		float: none;
	}

	.nav-drop.hidden-mobile {
		display: block;
	}

	.testimonials-see-all {
		margin-left: 20px;
	}

	.menu-button {
		color: var(--black-2);
		background-color: rgba(0, 0, 0, 0);
		margin-top: -27px;
		margin-bottom: 16px;
		margin-left: 0;
	}

	.menu-button.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.footer-nav {
		text-align: center;
		flex-flow: column;
		justify-content: center;
		align-items: flex-end;
		max-width: none;
		padding-top: 20px;
	}

	.footer-nav-li {
		justify-content: center;
		width: auto;
		margin-bottom: 0;
		margin-right: 0;
		display: flex;
	}

	.section-footer {
		flex-flow: column;
		padding: 0 10px;
	}

	.header-strip.sticky {
		position: fixed;
		z-index: 1000;
	}

	.div-block-21 {
		z-index: 999;
		background-color: var(--white);
		border-bottom: 1px solid rgba(51, 51, 51, 0.08);
		position: fixed;
		top: 26px;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.nav-menu-3 {
		margin-top: 0;
	}

	.account-button-mobile {
		margin-right: 10px;
	}

	.div-block-22 {
		display: none;
	}

	.icon-7 {
		color: var(--black-3);
	}

	.mobile-search {
		display: flex;
	}

	.mobile-search.onlymob {
		display: none;
	}

	.button-copy {
		margin-right: 10px;
		display: none;
	}

	.main-content-outer-wrap {
		flex-direction: column;
	}

	.left-nav-col {
		display: none;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.main-content-inner-wrap {
		flex-direction: column;
		display: block;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.container-3 {
		max-width: 768px;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.footer-nav-social {
		justify-content: center;
		width: auto;
		margin-bottom: 20px;
		margin-right: 0;
		display: flex;
	}

	.right-side-col {
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		width: 100%;
		min-width: 0;
		max-width: none;
		margin-top: 2rem;
		margin-left: 0;
		padding-top: 0;
	}

	.paragraph {
		max-width: none;
		font-size: 0.95rem;
	}

	.slide-5 {
		background-position: 0 0, 66%;
	}

	.account-login-cb {
		margin-top: 0;
		padding-left: 40px;
		padding-right: 40px;
	}

	.slide-6 {
		background-position: 50%;
	}

	.slider-5 {
		margin-top: 0;
	}

	.slide-7 {
		background-position: 0 0, 70% 0;
		background-size: auto, auto;
	}

	.login-button {
		margin-bottom: 1rem;
		margin-right: 0;
	}

	.online-banking-heading {
		font-size: 30px;
		line-height: 35px;
	}

	.slideshow-wrapper {
		padding-left: 40px;
		padding-right: 40px;
	}

	.logo-overlay {
		background-size: 450px;
	}

	.div-block-26 {
		flex-wrap: wrap;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}

	.div-block-27 {
		width: auto;
		max-width: none;
	}

	.desktop-search-2,
	.mobile-search-copy,
	.mobile-search-copy,
	.mobile-search-2 {
		display: none;
	}

	.newsimgcon {
		height: auto;
	}

	.servicescon {
		width: auto;
		max-width: none;
	}

	.callouttextwrapper {
		width: 80%;
	}

	.button.hide-desk {
		display: flex;
	}

	.header-strip {
		padding-left: 40px;
	}
}

@media screen and (max-width: 767px) {
	.button-sub-2 {
		text-align: center;
		margin-top: 7px;
		margin-bottom: 9px;
		font-size: 12px;
		display: flex;
	}

	.slider {
		z-index: 445;
		margin-top: 85px;
		position: static;
	}

	.navigation {
		z-index: 999;
		margin-top: 86px;
	}

	.link-block {
		width: 50%;
	}

	.icon-row {
		z-index: 500;
		background-color: var(--black);
		position: relative;
	}

	.top-nav {
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
	}

	.brand {
		z-index: 460;
		margin-top: -3px;
		margin-bottom: -12px;
		margin-left: 10px;
		padding-left: 0;
	}

	.nav-link {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.nav-link:hover {
		color: var(--dark-slate-grey);
		background-color: rgba(173, 175, 174, 0.15);
	}

	.nav-link-row {
		z-index: 450;
		clear: left;
		margin-top: -68px;
		margin-bottom: 0;
		position: relative;
	}

	.nav-menu {
		z-index: 500;
		background-color: var(--white);
		position: relative;
		top: -35%;
	}

	.button {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 12px;
		display: flex;
	}

	.login-field {
		float: left;
		width: 49%;
		margin-bottom: 10px;
	}

	.login-form-wrapper {
		float: left;
		opacity: 0;
		text-align: center;
		width: 100%;
		height: auto;
		padding-left: 30px;
		padding-right: 10px;
		display: inline-block;
		transform: translate(0, -74px);
	}

	.login-form {
		flex-flow: wrap;
		justify-content: center;
		width: 100%;
		display: flex;
	}

	.login-div {
		text-align: center;
		border-radius: 0;
		justify-content: flex-end;
		height: 210px;
		margin-top: -115px;
		margin-right: 0;
		display: flex;
		top: 113px;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.button-2.login-button {
		float: left;
		width: 30%;
	}

	.news-section {
		z-index: 500;
		background-color: var(--white);
		flex-wrap: wrap;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.div-block-2 {
		width: 80%;
	}

	.search {
		z-index: 460;
		margin-top: 20px;
		position: relative;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.div-block-8 {
		z-index: 460;
		background-color: rgba(0, 0, 0, 0);
		margin-top: -1px;
		margin-bottom: 1px;
		margin-right: 50px;
		position: relative;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.callout-image {
		z-index: 500;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.heading-3 {
		font-size: 40px;
		line-height: 45px;
	}

	.text-block {
		font-size: 15px;
		line-height: 20px;
	}

	.heading-4 {
		font-size: 40px;
		line-height: 45px;
	}

	.heading-4.white {
		font-size: 40px;
		line-height: 30px;
	}

	.testimonial,
	.footer-section {
		z-index: 500;
		position: relative;
	}

	.heading-6 {
		background-size: 40px;
		font-size: 30px;
		line-height: 35px;
	}

	.div-block-12 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.heading-7 {
		background-size: 40px;
	}

	.slider-4 {
		width: 450px;
	}

	.right-arrow-2 {
		margin-right: -12px;
		padding-right: 0;
	}

	.contact-us {
		z-index: 500;
		background-color: #1a1a1a;
		position: relative;
	}

	.div-block-16 {
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 40px;
	}

	.div-block-17,
	.div-block-18 {
		justify-content: center;
		width: 100%;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.footer-copyright {
		float: none;
	}

	.column-3 {
		align-items: center;
		overflow: hidden;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.columns {
		flex-wrap: wrap;
	}

	.div-block-19 {
		margin-bottom: 0;
	}

	.menu-button {
		margin: 26px 6px 18px 8px;
		position: static;
	}

	.menu-button.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.footer-nav {
		flex-direction: column;
		width: 100%;
		max-width: none;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.footer-nav-li {
		flex-flow: wrap;
		width: auto;
		max-width: 100%;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.footer-nav-a,
	.facebook-label {
		font-size: 16px;
	}

	.section-footer {
		z-index: 500;
		justify-content: center;
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		position: relative;
	}

	.div-block-21 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.account-button-mobile {
		background-color: var(--powder-blue);
		color: var(--black-3);
		background-image: url('/20181016082423/assets/images/lock_black.svg');
		width: auto;
		font-size: 12px;
		display: block;
	}

	.account-button-mobile:hover {
		border-style: solid;
		border-color: var(--powder-blue);
		background-color: var(--white);
		color: var(--black-3);
	}

	.div-block-22 {
		z-index: 100;
		flex-direction: column;
		flex: 0 auto;
		order: 0;
		justify-content: flex-end;
		align-items: flex-end;
		width: auto;
		height: auto;
		margin-top: 10px;
		margin-bottom: -48px;
		padding-top: 0;
		padding-right: 10px;
		display: none;
		position: absolute;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.icon-7 {
		color: var(--black-3);
	}

	.mobile-search {
		margin-top: 20px;
	}

	.mobile-search.onlymob {
		display: none;
	}

	.button-copy {
		font-size: 12px;
		display: none;
	}

	.main-content-outer-wrap {
		flex-direction: column;
	}

	.main-content-inner-wrap {
		padding-top: 0;
	}

	.container-3 {
		max-width: 550px;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.content-outer-wrap {
		padding-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.footer-nav-social {
		flex-flow: wrap;
		width: auto;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.login-dropdown {
		float: none;
		width: 100%;
		margin-bottom: 10px;
		margin-right: 0;
	}

	.login-dropdown.login-field {
		width: 97%;
	}

	.paragraph {
		margin-bottom: 0.5rem;
		font-size: 0.85rem;
		line-height: 1.4rem;
	}

	.account-login-cb {
		width: 100%;
		height: 50vh;
		margin-top: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		display: none;
	}

	.slide-6 {
		background-position: 0 0, 78% 0;
	}

	.slider-5 {
		width: 100%;
		height: 60vh;
		margin-top: 0;
	}

	.bg-color-wrapper {
		flex-wrap: wrap;
	}

	.online-banking-heading {
		font-size: 35px;
		line-height: 40px;
	}

	.logo-overlay {
		display: none;
	}

	.div-block-27 {
		flex-flow: column;
		align-items: center;
		width: 70%;
	}

	.desktop-search-2,
	.mobile-search-copy,
	.mobile-search-copy,
	.mobile-search-2 {
		margin-top: 20px;
	}

	.newscon {
		width: 80%;
	}

	.newswrapper {
		flex-wrap: wrap;
		justify-content: center;
	}

	.newstitlewrapper {
		margin-bottom: 0;
	}

	.servicescon {
		flex-flow: column;
		align-items: center;
		width: 70%;
	}

	.header-strip {
		padding-left: 20px;
	}
}

@media screen and (max-width: 479px) {
	.button.mobile2 {
		width: auto;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
	}

	.button-wrapper-copy {
		flex-direction: column;
	}

	.button-sub-2 {
		width: 90%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 3px;
	}

	.account-login-btn.mobile {
		display: inline-block !important;
	}

	.footer_link-list {
		flex-direction: column;
	}

	.slider {
		margin-top: 115px;
	}

	.navigation {
		z-index: 999;
		margin-top: 70px;
		margin-bottom: -103px;
		position: absolute;
	}

	.link-block {
		width: 80%;
	}

	.icon-row {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.top-nav {
		padding-top: 10px;
		padding-bottom: 0;
	}

	.navbar {
		background-color: var(--gainsboro);
		justify-content: center;
		align-items: stretch;
		width: 100%;
		position: fixed;
		top: 110px;
	}

	.brand {
		max-width: 245px;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	.nav-link {
		color: var(--black);
	}

	.nav-link:hover {
		background-color: rgba(173, 175, 174, 0.12);
	}

	.nav-link-row {
		justify-content: space-between;
		align-self: flex-end;
		width: 100%;
		height: auto;
		margin-top: 0;
		display: block;
		position: relative;
	}

	.nav-menu {
		margin-top: 0;
	}

	.button {
		background-size: 15px;
		width: 136px;
		margin-top: 8px;
		padding: 5px 15px 5px 35px;
		font-size: 11px;
		display: block;
		position: absolute;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.login-field {
		float: none;
		width: 100%;
		min-width: 0;
		max-width: 250px;
		margin-top: -4px;
		display: inline-block;
	}

	.login-form-wrapper {
		float: none;
		opacity: 0;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		display: block;
		transform: translate(0, -74px);
	}

	.login-form {
		flex-direction: column;
		align-items: center;
		width: 300px;
		display: flex;
	}

	.login-div {
		height: 240px;
		margin-top: -45px;
		top: 89px;
	}

	.button-2.login-button {
		float: none;
		width: 82%;
		display: inline-block;
	}

	.news-section {
		padding: 0;
	}

	.div-block-2 {
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
	}

	.div-block-3 {
		border-radius: 10px;
	}

	.heading-2 {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 20px;
		line-height: 25px;
	}

	.image-3 {
		border-radius: 0;
		width: 100%;
	}

	.div-block-4,
	.div-block-5 {
		border-radius: 10px;
	}

	.search {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.search-input {
		height: 30px;
		margin-right: -30px;
	}

	.search-button {
		background-size: 12px;
		width: 30px;
		height: 30px;
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 0px;
		padding-left: 0;
	}

	.search-button:hover {
		background-size: 13px;
	}

	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.div-block-8 {
		margin-top: 0;
		margin-bottom: 5px;
		margin-right: 0;
		display: none;
	}

	.image-4,
	.image-5 {
		width: 100%;
	}

	.heading-3 {
		font-size: 25px;
		line-height: 30px;
	}

	.text-block {
		font-size: 15px;
		line-height: 20px;
	}

	.blue-button {
		font-size: 12px;
	}

	.text-block-2,
	.text-block-3,
	.text-block-4 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.heading-4 {
		font-size: 25px;
		line-height: 30px;
	}

	.heading-4.white {
		line-height: 40px;
	}

	.testimonial {
		align-items: center;
		height: 400px;
		padding-bottom: 10px;
		padding-left: 0;
	}

	.paragraph-2 {
		text-align: left;
		width: auto;
		max-width: none;
		padding-left: 0;
	}

	.heading-6 {
		margin-bottom: 6px;
		margin-left: 18px;
		padding-left: 50px;
		font-size: 20px;
	}

	.heading-7 {
		background-size: 35px;
		margin-left: 20px;
		padding-left: 40px;
		font-size: 20px;
		line-height: 35px;
	}

	.slider-4 {
		width: 100%;
		height: 133px;
		margin-bottom: 10px;
		margin-left: 10px;
		margin-right: 10px;
	}

	#testimonials_module_contentbox_container.slider-4 {
		width: calc(100% - 20px);
	}

	.right-arrow-2 {
		height: 20px;
		margin-right: 10px;
		position: absolute;
		top: auto;
		bottom: 0%;
		left: auto;
		right: 0%;
	}

	.left-arrow-2 {
		height: 20px;
		margin-left: 10px;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: auto;
	}

	.slide-3,
	.slide-4 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-block-7 {
		font-size: 20px;
		line-height: 25px;
	}

	.div-block-16 {
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;
		padding-top: 30px;
	}

	.link-block-2 {
		width: 100%;
		max-width: 50%;
		margin-bottom: 20px;
		margin-right: 0;
	}

	.div-block-17 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.div-block-18 {
		justify-content: center;
		width: 100%;
		margin-bottom: 10px;
	}

	.image-9 {
		width: 40%;
	}

	.text-block-8 {
		text-align: center;
		width: 100%;
	}

	.footer-signoff-link-wrap {
		text-align: center;
	}

	.footer-copyright {
		text-align: center;
		margin-bottom: 0.5rem;
	}

	.column-3 {
		margin-bottom: 0;
	}

	.column-2 {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.columns {
		flex-wrap: wrap;
		padding-bottom: 0;
	}

	.div-block-19 {
		padding-left: 0;
	}

	.news-see-all {
		margin-bottom: 13px;
		margin-left: 10px;
	}

	.div-block-20 {
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}

	.testimonials-see-all {
		height: 45px;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 10px;
	}

	.menu-button {
		z-index: 999;
		width: auto;
		height: 50px;
		margin: 3px 0 46px;
		position: fixed;
		top: 36px;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.menu-button.w--open {
		margin-left: 0;
	}

	.footer-nav {
		width: 90%;
		padding-bottom: 10px;
	}

	.footer-nav-li {
		flex-flow: column;
	}

	.footer-nav-a.blue,
	.footer-nav-a.orange,
	.facebook-label {
		font-size: 15px;
	}

	.div-block-21 {
		border-bottom-style: none;
		justify-content: space-between;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		top: 36px;
	}

	.account-button-mobile {
		background-size: 15px;
		padding: 5px 15px 5px 35px;
		font-size: 11px;
		display: none;
	}

	.div-block-22 {
		justify-content: flex-end;
		align-items: center;
		left: 142px;
	}

	.mobile-search {
		z-index: 600;
		align-self: flex-end;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		margin-right: 100px;
		padding-bottom: 0;
		display: flex;
		position: relative;
	}

	.mobile-search.onlymob {
		display: flex;
	}

	.button-copy {
		z-index: 1000;
		background-color: var(--powder-blue);
		background-image: url('/20181016082423/assets/images/lock_black.svg');
		background-position: 50%;
		background-size: 13px;
		border-style: solid;
		border-color: rgba(0, 0, 0, 0);
		width: 30px;
		height: 30px;
		margin-top: 0;
		padding: 5px 15px 5px 0;
		font-size: 11px;
		display: block;
		position: absolute;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.button-copy:hover {
		border-style: solid;
		border-color: var(--powder-blue);
		background-color: var(--white);
	}

	.inside-content-sect {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.container-3 {
		max-width: none;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0;
	}

	.content-outer-wrap {
		padding-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;
	}

	.login-dropdown {
		float: none;
		width: 100%;
		margin-bottom: 10px;
	}

	.account-login-cb {
		height: 60vh;
	}

	.slide-6 {
		background-size: auto, auto;
	}

	.login-button {
		font-size: 12px;
	}

	.online-banking-heading {
		font-size: 20px;
		line-height: 25px;
	}

	.logo-overlay {
		height: 300px;
		top: auto;
		bottom: -40%;
		left: 0%;
		right: 0%;
	}

	.desktop-search-2,
	.mobile-search-copy {
		z-index: 600;
		align-self: flex-end;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		margin-right: 50px;
		padding-bottom: 0;
		display: flex;
		position: relative;
	}

	.mobile-search-copy {
		z-index: 600;
		align-self: flex-end;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		margin-right: 50px;
		padding-bottom: 0;
		display: none;
		position: relative;
	}

	.mobile-search-2 {
		z-index: 600;
		align-self: flex-end;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		margin-right: 50px;
		padding-bottom: 0;
		display: flex;
		position: relative;
	}

	.newsimgcon {
		border-radius: 10px;
	}

	.newscon {
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
	}

	.newstitlewrapper {
		padding-left: 0;
	}

	.testimonialscon {
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/


/*********************** Header **********************/
/*****************************************************/
.header-container {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000 !important;
	background-color: #fff;
}

@media screen and (min-width: 992px) {
	.sticky .section-divider,
	.sticky .slider {
		padding-top: 0px;
	}

	.sticky .header-container .top-nav.div-block-21 {
		display: none;
	}

	.sticky .navigation {
		margin-top: 26px;
	}
}

/********************* Login Form *******************/
/*****************************************************/
.login-form {
	float: left;
}

@media screen and (max-width: 991px) {
	.button-2.login-button {
		padding-top: 0;
	}

	.div-block-24 {
		justify-content: flex-start;
	}
}

@media screen and (max-width: 767px) {
	.button-2.login-button {
		float: none;
		padding-top: 0;
		margin: 0 auto;
	}

	.div-block-24 {
		justify-content: center;
	}

	.login-field {
		width: 48%;
	}
}

@media (max-width: 479px) {
	.login-form {
		float: none;
		width: 97%;
		margin: 0 auto;
	}

	.link-2,
	.text-block-9 {
		line-height: 12px;
	}

	.button-2.login-button {
		line-height: 14px;
		width: 97%;
		padding-top: 0;
		margin-left: -5px;
		max-width: 250px;
		margin-left: -5px;
	}

	.login-field {
		width: 100%;
	}
}

/***************** Accesible menu **********************/
/*****************************************************/
@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}

.accesible-navigation-menu {
	width: 100%;
}

.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu li {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px;
	position: relative;
}

.accesible-navigation-menu li.menu-li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul {
	display: none;
	background-color: #fff;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul li {
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul a {
	padding: 0.625rem;
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	border-bottom: 1px solid transparent;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.menu-li .container-wrapper ul a:hover,
.accesible-navigation-menu li.menu-li .container-wrapper ul a.w--current {
	background-color: rgba(47, 108, 160, 0.2);
}

.accesible-navigation-menu li.menu-li.open .container-wrapper,
.accesible-navigation-menu li.menu-li:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
	border-top: 1px solid #0eb484;
}

.accesible-navigation-menu li.menu-lik.open .container-wrapper ul,
.accesible-navigation-menu li.menu-li:hover .container-wrapper ul {
	display: inline-block;
}

/************* Mega Menu ******************/
/*************************************************/
.accesible-navigation-menu li.menu-li:last-child .container-wrapper.has-mega-menu {
	left: -330px;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu {
	width: 500px;
	background-color: #fff;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul {
	width: 100%;
	margin: 0px;
	padding: 0px;
	display: none;
	padding-top: 1rem;
	padding-bottom: 1rem;
	flex-wrap: wrap;
}

.accesible-navigation-menu li.menu-li.open .container-wrapper.has-mega-menu ul {
	display: flex;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li {
	padding: 0px;
	margin: 0px;
	display: inline-block;
	width: 49%;
	padding-right: 5px;
	padding-left: 5px;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li a {
	margin-bottom: 15px;
	display: inline-block;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li a:hover {
	background-color: transparent;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li ul {
	padding-top: 0px;
	padding-bottom: 0px;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li ul li {
	width: 100%;
	padding-right: 0px;
	padding-left: 0px;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li ul li a {
	padding: 0.625rem;
	margin: 0px;
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.menu-li .container-wrapper.has-mega-menu ul li ul li a:hover {
	background-color: rgba(184, 184, 184, 0.12);
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu {
	height: 50vh;
	max-height: calc(100% - 100px);
	margin-top: 100px;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #fff;
}

.mm-listitem:after {
	left: 0px;
	border-color: transparent;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.mm-wrapper_opened .drop-down-navigation-wrapper {
	z-index: 100 !important;
}

.mobile-navigation-menu li a.nav-a {
	color: var(--black);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 60px;
	padding-right: 60px;
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	transition: all 0.2s;
	line-height: 20px;
	border-bottom: 1px solid transparent;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li a.nav-a.active {
	color: var(--dark-slate-grey);
	background-color: rgba(173, 175, 174, 0.15);
	text-decoration: underline;
}

.mobile-navigation-menu li.has-submenu div.mm-panel a.nav-a {
	font-size: 14px;
	line-height: 17px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.mm-listitem_opened > .mm-panel {
	padding-top: 0px;
	padding-bottom: 0px;
}

.mm-menu_position-bottom.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-front.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-top.mm-menu_opened ~ .mm-wrapper__blocker {
	z-index: 0 !important;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
	border-color: transparent;
}

.mm-btn_next:after {
	border-color: #333;
}

.mm-wrapper_opened .login-div {
	display: none;
}

@media screen and (max-width: 560px) {
	.mobile-navigation-menu {
		margin-top: 115px;
	}
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext a {
	color: inherit !important;
}

.cbtext.white p,
.cbtext.white li,
.cbtext.white span,
.cbtext.white td,
.cbtext.white a,
.cbtext.white a:hover,
.cbtext.white h1,
.cbtext.white h2,
.cbtext.white h3,
.cbtext.white h4,
.cbtext.white h5,
.cbtext.white h6 {
	color: #fff !important;
}

.cbtext.white table.styled td,
.cbtext.white table.styled td a,
.cbtext.white table.styled td * {
	color: #333 !important;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.text-block-7 p {
	margin-bottom: 20px;
}

.heading-2 a {
	text-decoration: none !important;
	border-bottom: 0px !important;
	font-size: inherit !important;
	line-height: inherit !important;
	color: inherit !important;
	font-family: inherit !important;
	line-height: inherit !important;
}

#loginFormContainerHomepage .login-field,
#loginFormContainerHomepage .login-dropdown {
	background-color: #fff;
	width: 100%;
	height: auto;
	float: none;
	margin-bottom: 10px;
	padding: 8px 12px;
	color: #333;
}

#loginFormContainerHomepage .button-2.login-button {
	padding: 9px 25px;
	height: auto;
	float: none;
	border-radius: 100px;
	margin-top: 1.5rem;
	border: 2px solid var(--powder-blue);
	background-color: var(--powder-blue);
	color: #333;
	border-radius: 100px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
}

#loginFormContainerHomepage .button-2.login-button:hover {
	border-style: solid;
	border-color: var(--white);
	background-color: var(--white);
	color: var(--black);
}

#loginFormContainerHomepage .div-block-24 {
	margin-top: 1.5rem;
}

.slide-link.enroll {
	border-bottom: 2px solid var(--white);
	margin-left: 0;
	margin-right: 0;
	font-family: Inter, sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: var(--white);
}

.slide-link.enroll:hover {
	opacity: 0.65;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.homebox .text-block-9 {
	line-height: 14px;
	color: #fff;
	margin-top: 3px;
}

.cbtext.white .home_button {
	color: #333 !important;
}

.cbtext.white .home_button a {
	color: #333 !important;
}

.cbtext.white .home_button:hover {
	color: #000000 !important;
}

.cbtext.white .home_button:hover a,
.cbtext.white .home_button a:hover {
	color: #000000 !important;
}


/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p:last-child {
	margin-bottom: 0px;
}

.slide-nav-container {
	position: relative;
	bottom: 80px;
	height: 40px;
	display: inline-block;
	top: auto;
	float: left;
	margin-left: 100px;
}

@media screen and (max-width: 991px) {
	.slide-nav-container {
		margin-left: 40px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.section-divider,
.slider {
	padding-top: 146.5px;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a:hover,
.breadcrumbs-list li:last-child a {
	color: var(--black);
	border-bottom: 1px solid transparent;
	line-height: 1.8rem;
}

.left-nav-link-3.active {
	background-color: var(--section-hover);
	color: var(--sea-green-2);
	border-left-style: none;
	border-left-color: #e41356;
	width: 218px;
	padding-left: 0.63rem;
	padding-right: 0;
}

.left-nav-link-3.grandchild-link.active {
	background-color: var(--section-hover);
	color: var(--sea-green-2);
	border-left-color: #e41356;
	width: 206px;
}

.left-nav-link-3.greatgrandchild-link.active {
	box-shadow: none;
	color: var(--sea-green-2);
	border-left-color: #e41356;
	width: 192px;
}

.inside-content-sect.no-sidebars .main-content-inner-wrap {
	width: 65%;
	min-width: 800px;
	margin: 0 auto;
}

@media (max-width: 991px) {
	.section-divider,
	.slider {
		padding-top: 0;
	}

	.inside-content-sect.no-sidebars .main-content-inner-wrap {
		width: 100%;
		min-width: auto;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-side-col td img {
	max-width: none;
}

.right-side-col .box {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 0.4rem;
	color: var(--dark-slate-grey);
	margin-bottom: 10px;
}

.right-side-col .box p {
	color: var(--dark-slate-grey);
}

.right-side-col .box h4,
.right-side-col .box h3 {
	color: var(--sea-green-2);
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-left: -10px;
	padding-top: 0;
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 10px;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col a {
	border-bottom: 1px solid var(--sea-green-2);
	color: var(--dark-slate-grey);
	background-color: rgba(0, 0, 0, 0);
	margin-left: 10px;
	padding-left: 0;
	padding-right: 0;
	text-decoration: none;
	transition: all 0.3s;
}

.right-side-col a:hover {
	color: var(--medium-sea-green);
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 1px rgba(0, 0, 0, 0);
}

@media (max-width: 991px) {
	.right-side-col {
		padding-top: 20px;
		padding-left: 0px;
	}
}

/*************** Left Sidebar ********************/
/*************************************************/
.left-sidebar .box {
	padding: 15px;
}

/****************** Bottom Sidebar ***************/
/**************************************************/
.bottom-sidebar {
	font-size: 14px;
	line-height: 20px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 60px;
}

.bottom-sidebar .card-2 {
	background-color: #fff;
	border: 1px solid #eff0f6;
	border-radius: 24px;
	box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06);
}

.bottom-sidebar .card-2.link-card {
	color: #1a1a1a;
	transform-style: preserve-3d;
	border-color: #deefea;
	border-radius: 20px;
	text-decoration: none;
	transition: transform 0.3s, color 0.3s;
	overflow: hidden;
	box-shadow: 0 2px 7px rgba(15, 93, 75, 0.11);
}

.bottom-sidebar .card-2.link-card {
	margin-right: 32px;
	margin-bottom: 28px;
	width: calc(33.3% - 22px);
	transform-style: preserve-3d;
	animation: 1s ease-out 0s 1 appearonload;
}

@keyframes appearonload {
	0% {
		transform: translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		opacity: 0;
	}

	100% {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		opacity: 1;
	}
}

.bottom-sidebar .card-2.link-card:nth-child(3n + 3) {
	margin-right: 0px;
}

.bottom-sidebar .pd---content-inside-card {
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
}

.bottom-sidebar .card-2.link-card img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-height: 220px;
	max-width: 100%;
	border-radius: 20px;
}

.bottom-sidebar .card-2.link-card p {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 24px;
	color: #1a1a1a;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}

.bottom-sidebar .card-2.link-card p:last-child {
	margin-bottom: 0px;
}

.bottom-sidebar .card-2.link-card h3 {
	font-style: normal;
}

@media (max-width: 991px) {
	.bottom-sidebar .card-2.link-card,
	.bottom-sidebar .card-2.link-card:nth-child(3n + 3) {
		margin-right: 32px;
		width: calc(50% - 16px);
	}

	.bottom-sidebar .card-2.link-card:nth-child(even) {
		margin-right: 0px;
	}
}

@media (max-width: 767px) {
	.bottom-sidebar .card-2.link-card,
	.bottom-sidebar .card-2.link-card:nth-child(3n + 3) {
		margin-right: 0px;
		width: 100%;
	}

	.bottom-sidebar .pd---content-inside-card {
		padding: 32px 24px 40px;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.main-content a,
.left-sidebar a {
	border-bottom: 1px solid var(--sea-green-2);
	color: #696b6d;
	cursor: pointer;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.45s;
}

.main-content a:hover,
.left-sidebar a:hover {
	color: var(--medium-sea-green);
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

h1 {
	color: #424242;
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0%;
	padding: 0 0 10px;
	font-size: 50px;
	font-weight: 700;
	line-height: 55px;
}

h2 {
	color: #424242;
}

h3 {
	color: #424242;
}

h1 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: inherit;
	line-height: inherit;
	text-decoration: underline !important;
	transition: all 0.45s;
	font-weight: 600 !important;
	border-bottom: 0px !important;
}

h2 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 32px;
	line-height: 25px;
	text-decoration: underline !important;
	transition: all 0.45s;
	font-weight: 700 !important;
	border-bottom: 0px !important;
}

h3 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline !important;
	transition: all 0.45s;
	border-bottom: 0px !important;
	font-weight: 700 !important;
}

h4 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 18px;
	line-height: 24px;
	text-decoration: underline !important;
	transition: all 0.45s;
	border-bottom: 0px !important;
	font-weight: 700 !important;
}

h5 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline !important;
	font-weight: 700 !important;
	transition: all 0.45s;
	border-bottom: 0px !important;
}

h6 a {
	color: var(--sea-green-2) !important;
	cursor: pointer;
	border: 1px #000;
	height: auto;
	font-size: 12px;
	line-height: 18px;
	font-weight: 700 !important;
	text-decoration: underline !important;
	transition: all 0.45s;
	border-bottom: 0px !important;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: var(--medium-sea-green) !important;
	border-bottom: 0px !important;
	text-decoration: underline;
}

.main-content p {
	line-height: 22px;
}

.main-content li {
	line-height: 22px;
}

/* buttons */
.main-content-inner-wrap .button,
.main-content-inner-wrap a.primary,
.homeboxes a.primary,
.primary,
.main-content-inner-wrap button[type="submit"],
.main-content-inner-wrap .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	border: 2px solid var(--powder-blue) !important;
	background-color: var(--powder-blue) !important;
	color: var(--black-3) !important;
	text-align: center;
	text-transform: none;
	cursor: pointer;
	border-radius: 50px;
	min-width: 100px;
	padding: 0.8rem 1rem 0.7rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.main-content-inner-wrap .button:hover,
.main-content-inner-wrap a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.main-content-inner-wrap button[type="submit"]:hover,
.main-content-inner-wrap .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	border: 2px solid var(--powder-blue) !important;
	background-color: var(--white) !important;
	color: var(--powder-blue) !important;
}

.secondary,
.main-content-inner-wrap a.secondary,
.homeboxes a.secondary,
.main-content-inner-wrap button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.main-content-inner-wrap .cms_form_button.secondary,
.main-content-inner-wrap .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	border: 2px solid #b6b6b6 !important;
	background-color: rgba(0, 0, 0, 0) !important;
	color: var(--black-3) !important;
	text-align: center;
	text-transform: none;
	cursor: pointer;
	border-radius: 50px;
	min-width: 100px;
	padding: 0.8rem 1rem 0.7rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.secondary:hover,
.main-content-inner-wrap a.secondary:hover,
.main-content-inner-wrap button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.main-content-inner-wrap .cms_form_button.secondary:hover,
.main-content-inner-wrap .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	border: 2px solid #b6b6b6 !important;
	background-color: #b6b6b6 !important;
	color: #fff !important;
}

.tertiary,
.main-content-inner-wrap button[type="submit"].tertiary,
.cms_form_button.tertiary,
.main-content-inner-wrap a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	text-align: center;
	text-transform: none;
	cursor: pointer;
	border-radius: 50px;
	min-width: 100px;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
	color: #585b5d !important;
	background-color: rgba(0, 0, 0, 0) ! important;
	border: 2px solid #c4ccd1 !important;
	min-width: 60px;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.tertiary:hover,
.main-content-inner-wrap a.tertiary:hover,
.main-content-inner-wrap button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #000 !important;
	background-color: #f1f1f1 !important;
	transform: none;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 0.9rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 0.9rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #c4ccd1;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #000;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #00523c;
	color: #ffffff;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content-inner-wrap input[type="text"],
.main-content-inner-wrap input[type="tel"],
.main-content-inner-wrap input[type="password"],
.main-content-inner-wrap input[type="email"],
.main-content-inner-wrap select,
.main-content-inner-wrap textarea,
.main-content-inner-wrap input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
	margin-bottom: 10px;
}

.main-content-inner-wrap textarea {
	resize: vertical;
}

.main-content-inner-wrap label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.main-content-inner-wrap input[type="text"],
	.main-content-inner-wrap input[type="tel"],
	.main-content-inner-wrap input[type="password"],
	.main-content-inner-wrap input[type="email"],
	.main-content-inner-wrap select,
	.main-content-inner-wrap textarea,
	.main-content-inner-wrap input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-width: 9px;
	border-left-color: var(--powder-blue);
	color: var(--dark-slate-grey);
	background-color: rgba(0, 0, 0, 0);
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	font-size: 1.35rem;
	line-height: 2rem;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

.gr-form {
	background: #fff;
}

.pagination-wrapper > * {
	line-height: inherit;
}

/*********************** Footer **********************/
/*****************************************************/
.link.desktop {
	display: inline-block;
}

.link.mobile {
	display: none;
	color: #404040;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.footer_link-list {
	margin: 0px;
	width: 100%;
	text-align: center;
	justify-content: space-around;
	align-self: center;
	display: flex;
	padding: 0px;
	list-style-type: none;
	flex-wrap: wrap;
}

.footer_link-list li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
}

@media screen and (max-width: 991px) {
	.link.desktop {
		display: none;
	}

	.link.mobile {
		display: inline-block;
	}

	.footer_link-list {
		justify-content: center;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields {
	padding: 0 0 0 200px;
}

.ui-form-container .ui-form-label {
	left: -210px;
	width: 200px;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login.have-account-column {
	width: 550px;
}

.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#events_module.cms_list .fc-daygrid .fc-daygrid-day-number {
	color: #000;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 3px;
}


.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
	display: flex;
	align-items: center;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 3px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #666;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.9rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(0, 0, 0, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_divider {
	display: none;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field input[type="tel"],
	#jobs_module.cms_form .cms_field input[type="email"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(0, 0, 0, 0.15);
}

#locations_module.list .type_item h2.title,
#locations_module.list .type_item h2.title a {
	font-size: 1.5rem;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#news_module.cms_entity .cms_date h3,
#news_module.cms_list h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 20px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photos */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #006f51;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-side-col .box.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	min-width: auto;
	height: 40px;
	border-color: #424242;
	background-color: #424242;
}

.right-side-col .box.posts-blocks.posts-blocks-rss-feeds a,
.right-side-col .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-tags a {
	border-bottom: 1px solid transparent;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 40px;
	width: calc(100% - 50px);
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 10px 12px 8px !important;
}

.posts-node #post-add-comment .primary,
.posts-node #post-add-comment .secondary {
	margin-top: 0px;
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 10px;
}

/* resources */
#moduleDocuments_module.cms_list .cms_category_icon_title_row .cms_title {
	font-size: 1.7rem;
}

#moduleDocuments_module.cms_list .cms_item_icon_title_row .cms_item_title,
#moduleDocuments_module.cms_list .cms_item_icon_title_row .cms_item_title a {
	font-size: 1.3rem;
}

/* staf */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_list .cms_name,
#staffdirectory_module.cms_list .cms_name a {
	font-weight: bold;
	font-size: 1.1rem;
}

#staffdirectory_module.cms_list .cms_name {
	margin-bottom: 5px;
}

#staffdirectory_module.cms_list .cms_list_subheading {
	font-weight: bold;
	font-size: 1.3rem;
	margin-bottom: 10px;
}

#staffdirectory_module.cms_list .cms_list_item {
	align-items: flex-start;
	justify-content: flex-start;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* testomnilas */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* events */
#events_module.homepage_contentbox {
	font-size: 1.1rem;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 85px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 85px);
}

#events_module.cms_entity .registration-wrapper .button.event-registration-button {
	padding-left: 40px;
}

/* files */
.homebox .collection.files-collection {
	text-align: left;
	padding: 10px;
}

/* news */

/* partners */
#partners_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#partners_module.homepage_contentbox .cms_list_item {
	margin-bottom: 10px !important;
}

/* posts */
.homebox .collection.posts-collection {
	text-align: left;
	padding: 10px;
	font-size: 1rem;
}

.homebox .posts-collection.homepage-contentbox.article-items .article-image {
	width: auto;
}

.homebox .posts-collection.homepage-contentbox.article-items .article-image-link {
	border-bottom: 0px;
}

.homebox .posts-collection.homepage-contentbox.article-items .article {
	width: 100% !important;
}

/* rates */
.text-block #rates_module.homepage_contentbox *,
.text-block #rates_module.homepage_contentbox td {
	color: #333 !important;
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .testimonial-link {
	border-bottom: 0px;
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}
